<template>
  <UiButton
    v-bind="$attrs"
    data-el="m2-redirect-to-contact-page-button"
    data-test="m2-redirect-to-contact-page-button"
    :to="link"
    :title="props.title || t('ecom_ask_for_the_product')"
    :disabled="props.disabled"
    very-slim
    :icon-right="props.showIcon ? {
      name: 'chat-bubble',
      width: 20,
      height: 20,
    } : null"
    :uppercase="false"
    :variant="props.variant"
    class="w-full"
  >
    <slot>
      <span class="whitespace-nowrap">
        {{ t('ecom_ask_for_the_product') }}
      </span>
    </slot>
  </UiButton>
</template>

<script lang="ts" setup>
import type { ButtonVariants } from '@ui/components/UiButton/UiButton.types'
import usePlaceholders from '@cms/composables/usePlaceholders/usePlaceholders'

interface RedirectToContactPageButton {
  disabled?: boolean
  variant?: ButtonVariants
  title?: string
  showIcon?: boolean
  sku?: string
}
const props = withDefaults(defineProps<RedirectToContactPageButton>(), {
  disabled: undefined,
  variant: 'secondary',
  title: '',
  showIcon: true,
  sku: '',
})

const { t } = useI18n()
const placeholder = usePlaceholders(['contact']) || {}

const link = computed(() => {
  const href = placeholder.value?.contact?.link?.href ?? ''

  if (!href) {
    return ''
  }

  const params = {
    contact_as: 'customer',
    topic: 'product_availability',
  }

  if (props.sku) {
    Object.assign(params, { sku: props.sku })
  }

  const searchParams = new URLSearchParams(params)

  return `${href}?${searchParams.toString()}`
})
</script>
