/**
 * This composable is used to get placeholders data from the initial data provided by TYPO3.
 * Based on provided placeholders names, it returns an array of placeholders data.
 * It is used later to replace values provided by external API with real links from CMS
 *
 * @description In TYPO3 admin panel you can assign a link to placeholder key.
 * @example const { privacyPolicyUrl, termsUrl } = usePlaceholders(['privacyPolicyUrl', 'termsUrl']).value
 */
import { computed } from 'vue'
import type { T3InitialDataPlaceholder } from '@t3headless/nuxt-typo3'
import { useT3Api } from '#imports'

export interface PlaceholderData {
  [key: string]: T3InitialDataPlaceholder | undefined
}

export default function usePlaceholders<T extends string>(placeholders: T[]): ReturnType<typeof computed<{ [K in T]: T3InitialDataPlaceholder | undefined }>> {
  const { initialData } = useT3Api()

  /**
   * Return an object with requested placeholders and their values.
   * Even if the requested placeholder is not available in the initialData,
   * it will be in returned object (with undefined as a value).
   */
  return computed(() => {
    const placeholderData = initialData.value?.placeholders
    const result: { [K in T]: T3InitialDataPlaceholder | undefined } = {} as any
    if (!placeholders || !Array.isArray(placeholders)) {
      return result
    }

    placeholders.forEach((key) => {
      if (placeholderData?.[key]) {
        result[key] = placeholderData[key]
      }
      else {
        if (import.meta.client) {
          console.warn(`Placeholder "${key}" is not available in the initialData`)
        }
        result[key] = undefined
      }
    })

    return result
  })
}
